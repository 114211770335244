
.HomeSweetHome {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;

    --open-margin: 1px;
    --fold-margin: 5px;

    > .Container {
        min-height: 200px;
    
        transition: 200ms;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        justify-content: flex-start;

        > .Container {
            width: 100%;
        }
    }

    @media (min-width: 701px) {
        > .Container {
            margin-left: calc(240px + var(--open-margin));
            width: calc(100% - (240px + var(--open-margin)));
    
            &.Closed {
                margin-left: calc(100px + var(--fold-margin));
                width: calc(100% - (100px + var(--fold-margin)));
            }
        }        
    }

    .CustomsSpace{
        height: 50px;
        padding: 10px 24px;
        box-sizing: border-box;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 701px) {
            display: none;
        }

        .MobileMenuIcon {
            cursor: pointer;
        }
        .MobileLogoBox, .MobileUserImageBox{
            display: flex;

            .MobileLogo{
                width: 43px; height: 30px
            }
            .MobileUserImage{
                width: 24px;
                height: 24px;
                flex-shrink: 0;
    
                border-radius: 8px;
                background: rgba(182, 205, 216, 1);
            }
        }
    
        .PageMovingMenuArea{
            width: 100%; height: 100%; min-height: 100vh;
            position: fixed;
            top: 0; left:0; z-index: 9;
            background: rgba(20, 20, 21, 0.8);
    
            .PageMovingMenuCloseButton{
                position: absolute;
                top: 15px; right: 15px;
                color: rgb(255,255,255);
                cursor: pointer;
            };
    
            .PageMovingMenuContainer{
                width: calc(100% - 16px);
                position: absolute;
                display: flex; flex-direction: column;
                z-index: 2;
                border-radius: 10px;
                left: 8px; top: 60px;
                padding: 32px 24px;
                background-color: rgba(232, 238, 252, 1);
                color: rgb(68, 66, 72);
                font-weight: 500;
                box-sizing: border-box;

                img{
                    width: 48px;
                    height: 48px;
                    display: inline-block;
                    
                    -webkit-mask: url(https://static.webtoon.today/ddah/icon/link_with_arrow.svg) no-repeat 50% 50%;
                    mask: url(https://static.webtoon.today/ddah/icon/link_with_arrow.svg) no-repeat 50% 50%;
                    -webkit-mask-size: cover;
                    mask-size: cover;

                    background-color: yellowgreen;
                }
    
                .ButtonBox{
                    margin-bottom: 24px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                    color: rgba(60, 70, 81, 1);
                    font-weight: 600;   

                    .SVGIconLink{
                        path {
                            fill: rgba(60, 70, 81, 1);
                        }
                    }
                    .SVGIconDashboard{
                        path {
                            stroke: rgba(60, 70, 81, 1);
                        }
                    }
                }
    
                .ButtonBox:nth-last-child(1){
                    padding-top: 24px;
                    margin-bottom: 0px;
                    border-top: 1px solid rgba(224, 226, 235, 1);
                    font-weight: 600;
                    color: rgba(61, 106, 255, 1);
                }
            }
        }
    }

}

.Sidebar {
    @media (max-width: 700px) {
        display: none;
    }
    &.Closed .Long {
        display: none;
    }

    &:not(.Closed) .Short {
        display: none;
    }
    
    .HomeDrawerHeader{
        padding: 12px 24px 12px;
        .LogoImage{
            padding: 32px 0 16px 10px;
            margin-left: -4px;
            display: flex;
            justify-content: flex-start;
            
            .Long{
                width: 116px; height: 32px;
                object-fit: contain;
                object-position: 0 0;
                cursor: pointer;
            }

            .Short {
                width: 35px; height: 32px;
                object-fit: contain;
                object-position: 0 0;
                cursor: pointer;
            }
        }
        .MyMenu{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            .ProfileButton {
                height: 40px;
                font-size: unset;
                font-weight: unset;

                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .ProfileInfo{
                display: flex;
                align-items: center;
                gap: 10px;

                .UserImageBox{
                    display: flex;
                    width: 24px; height: 24px;
                    border-radius: 8px;
                    flex-shrink: 0;
                    object-fit: contain;
                }
                .UserName{
                    overflow: hidden;
                    text-overflow: ellipsis;   
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    cursor: default;
                }
            }

            .MyMenuButton{
                transition: 0.5s;
                cursor: pointer;
                &.Open{
                    transform: rotate(180deg);
                }
            }
            .MenuDropDown{
                width: 100%;
                padding: 12px 8px;
                box-sizing: border-box;
                
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                
                position: absolute;                
                left: 0; top: calc(100% + 34px);
                z-index: 1;

                border-radius: 8px;
                border: 1px solid rgba(236, 237, 243, 1);
                background: rgba(255,255,255,1);
                box-shadow: 0px 14px 24px 0px rgba(12, 16, 39, 0.12);
                transition: 1s;
                transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 360ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transform: none;
                transform-origin: 50% 0%;

                &.MenuOpen{
                    transform: scale(1);
                    opacity: 1;
                }
                &.MenuClose{
                    transform: scale(0.5);
                    opacity: 0;
                }

                .MenuDropDownItem{
                    width: 100%;
                    padding: 8px;

                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 14px;
                    
                    border-radius: 5px;
                    color: rgba(235, 81, 71, 1);
                }
            }
        }
    }
    .HomeDrawerBody {
        padding: 0 24px 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }
    .MuiDrawer-paper {
        z-index: 1;
        box-sizing: border-box;
        overflow: hidden;
        max-width: 240px;
    }
    .Row {
        height: 40px;
        padding: 10px;
        width: 300px; max-width: 192px;
        transition: 200ms;

        justify-content: flex-start;
        text-align: left;
        gap: 12px;

        color: rgba(158, 157, 164, 1);

        .MuiButton-label{
            gap: 12px;
            .MuiListItemIcon-root {
                min-width: 0;
                justify-content: center;
            }
            .MuiListItemText-root {
                white-space: nowrap;
            }
        }

        .SVGIconLink{
            path{
                fill: rgba(158, 157, 164, 1);
            }
        }
        .SVGIconDashboard{
            path{
                stroke: rgba(158, 157, 164, 1);
            }
        }

        &.Selected{
            color: rgba(60, 70, 81, 1);
            .SVGIconLink{
                path {
                    fill: rgba(60, 70, 81, 1);
                }
            }
            .SVGIconDashboard{
                path {
                    stroke: rgba(60, 70, 81, 1);
                }
            }
        }
        .MuiButton-label{
            gap: 12px;
            .MuiListItemIcon-root {
                min-width: 0;
                justify-content: center;
            }
            .MuiListItemText-root {
                white-space: nowrap;
                span{
                    font-family: Pretendard;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%; /* 16px */
                }
            }
        }
    }
    
    &.Closed{
        .MuiDrawer-paper {
            max-width: 100px;
        }
        .HomeDrawerHeader .MyMenu {
            .MyMenuButton, .UserName{
                display: none;
            }
        }
        .Row {
            max-width: 60px;
    
            .MuiListItemText-root {
                display: none;
            }
            
    
            &.SideFooter {
                justify-content: center;
            }
        }

    } 


    .SideFooter {
        width: 100%;
        max-width: calc(100% - 24px);

        position: absolute;
        bottom: 0;

        display: flex;
        flex-direction: row-reverse;
        justify-content: right;

        .span {
            overflow: hidden;
        }

        .MuiListItemIcon-root {
            height: 20px;
        }
    }
}
